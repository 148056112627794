<template>
  <div
    :class="[
      'c-sidebar',
      {
        'c-sidebar--mac': isMacOs,
      },
    ]"
  >
    <div class="c-sidebar__wrapper">
      <div class="c-sidebar__switcher">
        <div
          class="c-sidebar__avatar-wrapper u-has-tooltip"
          v-on:click="(...e) => onWebsiteClick(...e)"
        >
          <BaseAvatar
            class="c-sidebar__avatar c-sidebar__avatar--website"
            :availability="'online'"
            :avatar-id="tenantID"
            :avatar-url="currentTenant.tenantLogo"
            custom-size="42px"
            parent-color="#1c293b"
            cursor="pointer"
            type="website"
          ></BaseAvatar>
          <!-- <BaseNotification
            v-if="Object.keys(notification).length > 0"
            :color="notification.color"
            :size="notification.count ? 'mini' : 'micro'"
            :grow-with="notification.count"
            :class="[
              'c-sidebar__notification',
              {
                'c-sidebar__notification--count': notification.count > 0,
              },
            ]"
          >
            {{ notification.count > 99 ? "99+" : notification.count }}
          </BaseNotification> -->
          <BaseTooltip
            v-if="currentTenant.tenantName"
            align="right"
            color="white"
            direction="middle"
          >
            {{ currentTenant.tenantName }}
            <!-- <span class="c-sidebar__tooltip-tips"
              >({{ this.$t("TOOLTIPS.SIDEBAR_WEBSITE_OFFLINE") }})</span
            > -->
          </BaseTooltip>
        </div>
        <SidebarMenu
          v-if="menus.website"
          class="c-sidebar__menu"
          v-on:close="(e) => (menus.website = !1)"
          v-on:logout="(e) => (popups.confirmLogout.visible = !0)"
          v-on:switchWebsite="onSwitchWebsiteProceed"
          v-on:showInviteOperator="onInviteOperator"
        ></SidebarMenu>
      </div>
      <component
        :is="item.component"
        v-for="item in features"
        :key="item.id"
        :to="item.disabled ? features[0].link : item.link"
        v-on:click="(t) => (item.onClick ? item.onClick() : null)"
        :class="[
          'c-sidebar__feature',
          'c-sidebar__feature--' + item.id,
          'u-has-tooltip',
          {
            'c-sidebar__feature--disabled': item.disabled,
            'c-sidebar__feature--loading': false,
          },
        ]"
      >
        <BaseTooltip
          v-if="item.tooltip"
          direction="middle"
          align="right"
          color="white"
        >
          {{ item.tooltip }}
          <span v-if="item.tips" class="c-sidebar__tooltip-tips">
            ({{ item.tips }})
          </span>
        </BaseTooltip>
        <BaseIcon
          v-if="item.icon"
          :name="item.icon.name"
          :class="[
            'c-sidebar__icon',
            {
              'c-sidebar__icon--active': item.id === currentSection,
            },
          ]"
        ></BaseIcon>
        <span
          v-else
          :class="[
            'c-sidebar__icon',
            {
              'c-sidebar__icon--active': item.id === currentSection,
            },
          ]"
        ></span>
        <span v-if="item.label" class="c-sidebar__label u-ellipsis">
          {{ item.label }}
        </span>
        <span
          v-if="item.notifications && item.notifications.count > 0"
          :class="[
            'c-sidebar__notifications',
            'c-sidebar__notifications--' + item.notifications.color,
            'u-bold',
          ]"
        >
          {{
            item.notifications.count > 999 ? "99+" : item.notifications.count
          }}
        </span>
      </component>
    </div>
    <ConfirmLogout
      v-if="popups.confirmLogout.visible"
      v-on:close="(e) => (popups.confirmLogout.visible = !1)"
      v-on:logout="onConfirmLogout"
    ></ConfirmLogout>

    <InviteOperator
      v-if="popups.inviteOperator.visible"
      v-on:close="(e) => (popups.inviteOperator.visible = !1)"
      :tenantID="tenantID"
    ></InviteOperator>

    <ChatboxIntegrations
      v-if="popups.chatboxIntegrations.visible"
      v-on:close="(e) => (popups.chatboxIntegrations.visible = !1)"
      :website-id="tenantID"
      :website-name="currentTenant.tenantName"
      :website-domain="currentTenant.tenantWebsite"
    ></ChatboxIntegrations>
  </div>
</template>
<script>
// var o = g(i("5bf4"));
// var n = g(i("9bed"));
import ConfirmLogout from "@/components/Popups/ConfirmLogout.vue";
// import * as r from "@/components/SwitchPlan.vue";
// import * as s from "@/components/UpgradePopup.vue";
import ChatboxIntegrations from "@/components/ChatboxIntegrations.vue";
// import * as c from "@/classes/websites.js";
// import * as d from "@/mixins/plugins.js";
// import * as M from "@/classes/platformAssistant.js";

import SidebarMenu from "@/components/SidebarMenu.vue";
import InviteOperator from "@/components/Popups/InviteOperator.vue";
import * as u from "@/classes/platform.js";

import mitt from "mitt";
const emitter = mitt();

// const p = 36e5;
export default {
  components: {
    ChatboxIntegrations,
    SidebarMenu,
    InviteOperator,
    ConfirmLogout,
  },
  // mixins: [d.default],
  data() {
    return {
      isMacOs: "darwin" === u.default.getOs(),
      menus: {
        website: !1,
      },
      popups: {
        confirmLogout: {
          visible: !1,
        },
        chatboxIntegrations: {
          visible: !1,
        },
        inviteOperator: {
          visible: !1,
        },
      },
      acquireAlertsCountScheduler: null,
    };
  },
  showInviteOperator() {
    emitter.emit("inviteOperator");
  },
  computed: {
    tenantID() {
      return this.$store.getters["tenantsTalqui/getTenantID"];
    },
    currentTenant() {
      return (
        this.$store.getters["tenantsTalqui/getTenant"](this.tenantID) || {}
      );
    },
    features() {
      return [
        {
          id: "inbox",
          tooltip: this.$t("SIDEBAR.INBOX"),
          component: "router-link",
          link: {
            name: "app.website.inbox.index",
            params: {
              tenantID: this.tenantID,
            },
          },
          // notifications: {
          //   count: 10,
          //   color: "red",
          // },
        },
        {
          id: "campaigns",
          tooltip: this.$t("SIDEBAR.CAMPAIGNS"),
          component: "router-link",
          link: {
            name: "app.website.campaigns.index",
            params: {
              tenantID: this.tenantID,
              channel: "all",
            },
          },
          // disabled: this.isSafeModeEnabled,
        },
        {
          id: "analytics",
          tooltip: this.$t("SIDEBAR.ANALYTICS"),
          component: "router-link",
          link: {
            name: "app.website.analytics.messaging",
            params: {
              tenantID: this.tenantID,
            },
          },
          disabled: false,
        },
        {
          id: "plugins",
          tooltip: this.$t("SIDEBAR.PLUGINS"),
          component: "router-link",
          link: {
            name: "app.website.plugins.filter",
            params: {
              tenantID: this.tenantID,
              filter: "all",
            },
          },
          disabled: false,
        },
        {
          id: "setup",
          label: this.$t("SIDEBAR.SETUP"),
          tooltip: "Instale agora",
          component: "span",
          onClick: this.onChatboxSetupClick,
        },
        {
          id: "search",
          tooltip: "" + this.$t("SIDEBAR.SEARCH"),
          // tips: "CTRL+F",
          component: "span",
          onClick: this.onFeatureSearchClick,
          icon: {
            name: "search",
          },
        },
        {
          id: "settings",
          tooltip: this.$t("SIDEBAR.SETTINGS"),
          component: "router-link",
          link: {
            name: "app.settings.index",
          },
          disabled: false,
        },
      ];
    },
    // hotkeys() {
    //   return {
    //     "ctrl+f": this.onOpenSpotlight,
    //     "meta+f": this.onOpenSpotlight,
    //     "ctrl+k": this.onOpenSpotlight,
    //     "meta+k": this.onOpenSpotlight,
    //     "meta+w": this.onCloseAppDesktop,
    //   };
    // },
    currentSection() {
      let $routeName = this.$route.name;
      return $routeName.startsWith("app.settings.")
        ? $routeName.split(".")[1]
        : $routeName.split(".")[2];
    },
    // notification() {
    //   let e = {};
    //   const t = this.websitesWithoutCurrent.reduce(
    //     (e, t) => e + (t.count || 0),
    //     0
    //   );
    //   return (
    //     t > 0
    //       ? (e.count = t)
    //       : this.$store.getters["alerts/hasAlerts"] &&
    //         (e.color = this.$store.getters["alerts/hasFeedAlerts"]
    //           ? "red"
    //           : "white"),
    //     e
    //   );
    // },
    // pendingSection() {
    //   let e = this.$store.getters["common/getPendingRouteState"];
    //   let t = "";
    //   return (
    //     (t = startsWith(e, "app.settings.")
    //       ? e.split(".")[1]
    //       : e.split(".")[2]),
    //     t === this.currentSection && (t = ""),
    //     t
    //   );
    // },
    // websitesWithoutCurrent() {
    //   return this.websites.filter((e) => e.id !== this.websiteId);
    // },
    // isSafeModeEnabled() {
    //   return this.$store.getters["common/isSafeModeEnabled"];
    // },
    // setup() {
    //   return this.$store.getters["websites/getSetup"](this.websiteId);
    // },
    // shouldDisplaySetup() {
    //   return (
    //     this.setup &&
    //     !1 === this.setup.disabled &&
    //     this.setup.states &&
    //     this.setup.states.chatbox &&
    //     !this.setup.states.chatbox.done
    //   );
    // },
    // alertsCount() {
    // return this.$store.getters["alerts/getCount"];
    // },
    // features() {
    //   let e = [];
    //   return (
    //     this.websiteId &&
    //       (e.push(
    //         {
    //           id: "inbox",
    //           tooltip: this.$t("SIDEBAR.INBOX"),
    //           component: "router-link",
    //           link: {
    //             name: "app.website.inbox.index",
    //             params: {
    //               websiteId: this.websiteId,
    //             },
    //           },
    //           notifications: {
    //             count: (this.currentWebsite || {}).count,
    //             color: "red",
    //           },
    //         },
    //         {
    //           id: "visitors",
    //           tooltip: this.$t("SIDEBAR.VISITORS"),
    //           component: "router-link",
    //           link: {
    //             name: "app.website.visitors.index",
    //             params: {
    //               websiteId: this.websiteId,
    //             },
    //           },
    //           disabled: this.isSafeModeEnabled,
    //           notifications: {
    //             count: this.visitorsCount,
    //             color: "blue",
    //           },
    //         },
    //         {
    //           id: "contacts",
    //           tooltip: this.$t("SIDEBAR.CONTACTS"),
    //           component: "router-link",
    //           link: {
    //             name: "app.website.contacts.index",
    //             params: {
    //               websiteId: this.websiteId,
    //             },
    //           },
    //           disabled: this.isSafeModeEnabled,
    //         },
    //         {
    //           id: "campaigns",
    //           tooltip: this.$t("SIDEBAR.CAMPAIGNS"),
    //           component: "router-link",
    //           link: {
    //             name: "app.website.campaigns.index",
    //             params: {
    //               websiteId: this.websiteId,
    //               filterType: "types",
    //               filterValue: "all",
    //             },
    //           },
    //           disabled: this.isSafeModeEnabled,
    //         },
    //         {
    //           id: "helpdesk",
    //           tooltip: this.$t("SIDEBAR.HELPDESK"),
    //           component: "router-link",
    //           link: {
    //             name: "app.website.helpdesk.index",
    //             params: {
    //               websiteId: this.websiteId,
    //               filter: "articles",
    //             },
    //           },
    //           disabled: this.isSafeModeEnabled,
    //         },
    //         {
    //           id: "analytics",
    //           tooltip: this.$t("SIDEBAR.ANALYTICS"),
    //           component: "router-link",
    //           link: {
    //             name: "app.website.analytics.messaging",
    //             params: {
    //               websiteId: this.websiteId,
    //             },
    //           },
    //           disabled: this.isSafeModeEnabled,
    //         },
    //         {
    //           id: "plugins",
    //           tooltip: this.$t("SIDEBAR.PLUGINS"),
    //           component: "router-link",
    //           link: {
    //             name: "app.website.plugins.filter",
    //             params: {
    //               websiteId: this.websiteId,
    //               filter: "all",
    //             },
    //           },
    //           disabled: this.isSafeModeEnabled,
    //         },
    //         {
    //           id: "settings",
    //           tooltip: this.$t("SIDEBAR.SETTINGS"),
    //           component: "router-link",
    //           link: {
    //             name: "app.settings.index",
    //           },
    //           disabled: this.isSafeModeEnabled,
    //         }
    //       ),
    //       !this.currentWebsite.subscription ||
    //       (!0 === this.currentWebsite.subscription.active &&
    //         "Basic" !== this.currentWebsite.subscription.name)
    //         ? this.shouldDisplaySetup &&
    //           e.push({
    //             id: "setup",
    //             label: this.$t("SIDEBAR.SETUP"),
    //             component: "span",
    //             onClick: this.onChatboxSetupClick,
    //           })
    //         : e.push({
    //             id: "upgrade",
    //             label: this.$t("SIDEBAR.UPGRADE"),
    //             component: "span",
    //             onClick: this.onFeatureUpgradeClick,
    //           }),
    //       e.push({
    //         id: "search",
    //         tooltip: "" + this.$t("SIDEBAR.SEARCH"),
    //         tips: "CTRL+F",
    //         component: "span",
    //         onClick: this.onFeatureSearchClick,
    //         icon: {
    //           name: "search",
    //         },
    //       })),
    //     e
    //   );
    // },
    // visitorsCount() {
    //   return (this.$store.getters["visitors/getCount"] || {}).count;
    // },
    spotlight() {
      return this.$store.getters["common/getSpotlight"];
    },
    // status() {
    //   return this.$store.getters["status/getStatus"];
    // },
    // usersAvailable() {
    //   return this.$store.getters["websites/hasUsersAvailable"];
    // },
    // websites() {
    //   return this.$store.getters["websites/getWebsites"];
    // },
  },
  created() {
    // console.log("ROUTE", this.$route);
    // console.log("ROUTER", this.$router);
    // this.$store
    //   .dispatch("tenantsTalqui/acquireTenants", {
    //     organizationID: "8734f624-3f84-48b5-8644-7a6ab2c6b1af",
    //   })
    //   .then((data) => {
    //     console.log("RESSSS", data);
    //   });
    // this.acquireAlertsCount();
  },
  mounted() {
    emitter.on("inviteOperator", this.onInviteOperator);
    // window.addEventListener("keydown", this.onSwitchWebsite),
    //   (this.acquireAlertsCountScheduler = setInterval(() => {
    //     this.$store.getters["common/isConnected"] && this.acquireAlertsCount();
    //   }, p));
  },
  beforeUnmount() {
    emitter.off("inviteOperator", this.onInviteOperator);
    // window.removeEventListener("keydown", this.onSwitchWebsite);
  },
  unmounted() {
    // this.acquireAlertsCountScheduler &&
    //   (clearInterval(this.acquireAlertsCountScheduler),
    //   (this.acquireAlertsCountScheduler = null));
  },
  methods: {
    // acquireAlertsCount() {
    //   this.$store
    //     .dispatch("alerts/acquireCount")
    //     .then(() =>
    //       this.alertsCount.updates > 0
    //         ? this.$store.dispatch("alerts/acquireLatestUpdate")
    //         : Promise.resolve()
    //     );
    // },
    onConfirmLogout() {
      this.popups.confirmLogout.visible = false;
      location.href = "/initiate/logout/";
    },
    onInviteOperator() {
      this.popups.inviteOperator.visible = true;
    },
    onFeatureSearchClick() {
      this.$store.commit("common/setSpotlight", {
        value: !this.spotlight,
      });
      // this.$pluginExists("urn:crisp.im:message-search:0")
      //   ? this.$store.commit("common/setSpotlight", {
      //       value: !this.spotlight,
      //     })
      //   : s.default.show({
      //       pluginUrn: "urn:crisp.im:message-search:0",
      //       pluginName: "Message Search",
      //       plan: "pro",
      //     });
    },
    // onFeatureUpgradeClick() {
    //   r.default.show(this.websiteId);
    // },
    onChatboxSetupClick() {
      this.popups.chatboxIntegrations.visible = !0;
    },
    // onOpenSpotlight(e) {
    //   this.$pluginExists("urn:crisp.im:message-search:0") &&
    //     (!0 === this.spotlight
    //       ? this.$store.commit("common/setSpotlight", {
    //           value: !1,
    //         })
    //       : (e.preventDefault(),
    //         this.$store.commit("common/setSpotlight", {
    //           value: !0,
    //         })));
    // },
    // onCloseAppDesktop() {
    //   u.default.isPlatform() && M.default.hideWindow();
    // },
    onWebsiteClick() {
      this.menus.website = !this.menus.website;
    },
    // onSwitchWebsite(e) {
    //   let t = parseInt(e.key);
    //   if (
    //     t > 0 &&
    //     t <= 9 &&
    //     (e.ctrlKey || e.metaKey) &&
    //     u.default.isPlatform()
    //   ) {
    //     t -= 1;
    //     let e = this.websites;
    //     if (e.length > t) {
    //       let i = e[t].id;
    //       this.onSwitchWebsiteProceed(i);
    //     }
    //   }
    // },
    onSwitchWebsiteProceed(tenantID) {
      // console.log("@switch-tenant", tenantID);
      // c.default.setDefaultWebsiteId(e),
      this.menus.website = !1;
      window.location.replace(`/tenant/${tenantID}/inbox`);
      // this.$router
      //   .push({
      //     name: "app.website.inbox.index",
      //     params: {
      //       tenantID: tenantID,
      //     },
      //   })
      //   .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.c-sidebar {
  z-index: 3;
  width: 78px;
  height: 100%;
  background-color: #1c293b;
  -webkit-app-region: drag;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .c-sidebar__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 30px 0 12px;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    .c-sidebar__tooltip-tips {
      padding-left: 7px;
      color: #75869c;
    }
    .c-sidebar__switcher {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-bottom: 42px;
      .c-sidebar__avatar-wrapper {
        cursor: pointer;
        .c-sidebar__avatar {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          margin-bottom: 14px;
          border-radius: 100%;
          -webkit-transition: -webkit-box-shadow 0.15s linear;
          transition: -webkit-box-shadow 0.15s linear;
          transition: box-shadow 0.15s linear;
          transition:
            box-shadow 0.15s linear,
            -webkit-box-shadow 0.15s linear;
          &:hover {
            -webkit-box-shadow: 0 1px 3px 0 #000;
            box-shadow: 0 1px 3px 0 #000;
          }
        }
        .c-sidebar__avatar--website {
          margin-bottom: 0;
        }
      }
      .c-sidebar__notification {
        position: absolute;
        right: -2px;
        bottom: -4px;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        border: 2px solid #1c293b;
      }
      .c-sidebar__notification--count {
        right: -5px;
        bottom: -7px;
      }
      .c-sidebar__menu {
        position: absolute;
        top: 55px;
        left: 0;
      }
    }
    .c-sidebar__feature {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      margin-bottom: 2px;
      padding: 2px;
      width: 50px;
      height: 50px;
      border-radius: 2px;
      text-align: center;
      -webkit-transition: background-color 0.1s linear;
      transition: background-color 0.1s linear;
      cursor: pointer;
      will-change: background-color;
      .c-sidebar__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 24px;
        height: 24px;
        outline: 0;
        background-size: cover;
        text-align: center;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
      }
      .c-sidebar__label {
        position: absolute;
        right: -4px;
        bottom: calc(-50% + 4px);
        left: -4px;
        display: block;
        color: #fff;
        text-align: center;
        letter-spacing: 0.3px;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        -webkit-transition: color 0.1s linear;
        transition: color 0.1s linear;
      }
      .c-sidebar__notifications {
        position: absolute;
        right: -2px;
        bottom: 0;
        z-index: 1;
        display: inline-block;
        padding: 0 5px;
        min-width: 8px;
        height: 20px;
        border-radius: 2px;
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
        color: #fff;
        text-shadow: none;
        letter-spacing: 0.5px;
        font-size: 13px;
        line-height: 21px;
        -webkit-transition: bottom 75ms linear;
        transition: bottom 75ms linear;
        -webkit-animation-name: bounceIn;
        animation-name: bounceIn;
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
      .c-sidebar__notifications--blue {
        background-color: #0093ee;
      }
      .c-sidebar__notifications--orange {
        background-color: #fd7b1f;
      }
      .c-sidebar__notifications--red {
        background-color: #e0102b;
      }
      &:active {
        .c-sidebar__notifications {
          bottom: -4px;
        }
        background-color: hsla(0, 0%, 100%, 0.07);
      }
      &:hover {
        .c-sidebar__notifications {
          bottom: -4px;
        }
        background-color: hsla(0, 0%, 100%, 0.05);
      }
    }
    .c-sidebar__feature.u-has-tooltip {
      .c-base-tooltip {
        width: 140px;
      }
    }
    .c-sidebar__feature--search {
      margin: auto 0 0;
      width: 46px;
      height: 46px;
      border-radius: 100%;
      .c-sidebar__icon {
        fill: #75869c;
      }
    }
    .c-sidebar__feature--inbox {
      margin-bottom: 2px;
      .c-sidebar__icon {
        width: 29px;
        height: 29px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAyMCAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNjY0MTUgMTguODQzQzUuNTgxNjEgMTguODQzIDUuNDk4MjEgMTguODIwNSA1LjQyNjExIDE4Ljc3MjhDNS4yNzIzMyAxOC42NzE3IDUuMTk4NDkgMTguNDg0NCA1LjI0MjggMTguMzA2Mkw2LjAxNDI2IDE1LjE5ODVIMy4wMjIyM0MyLjgxNzIgMTUuMTk4NSAyLjYzOTExIDE1LjA1NjQgMi41OTc0MSAxNC44NTU1TDAuMDA5MzUzMiAyLjU3MjU0Qy0wLjAxNTg0MSAyLjQ1MjE1IDAuMDEwMjIxOSAyLjMyNzQ0IDAuMDgzMTk4MiAyLjIyODdDMC4xNTUzMDYgMi4xMjk5NyAwLjI2NTYzOSAyLjA2NTg4IDAuMzg4MTM1IDIuMDUyNjdMMTkuNTE5MiAwLjAwMjg0MTU1QzE5LjY1MTEgLTAuMDEyNzQ4MSAxOS43ODQyIDAuMDM2NjE5MiAxOS44NzcxIDAuMTMxMDIzQzE5Ljk3MDEgMC4yMjU0MjggMjAuMDE0MiAwLjM1OTY3MiAxOS45OTYgMC40OTA0NTJMMTguMjk0IDEzLjA5MDJDMTguMjY0NSAxMy4zMDUgMTguMDgxNCAxMy40NjYgMTcuODYzNCAxMy40NjZIMTIuODcwNkw1LjkyOTEyIDE4Ljc1MzhDNS44NTAwNiAxOC44MTM1IDUuNzU3MSAxOC44NDMgNS42NjQxNSAxOC44NDNaIiBmaWxsPSIjN0RBMUJCIi8+Cjwvc3ZnPgo=");
      }
      .c-sidebar__icon--active {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAyMCAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNjY0MTUgMTguODQzQzUuNTgxNjEgMTguODQzIDUuNDk4MjEgMTguODIwNSA1LjQyNjExIDE4Ljc3MjhDNS4yNzIzMyAxOC42NzE3IDUuMTk4NDkgMTguNDg0NCA1LjI0MjggMTguMzA2Mkw2LjAxNDI2IDE1LjE5ODVIMy4wMjIyM0MyLjgxNzIgMTUuMTk4NSAyLjYzOTExIDE1LjA1NjQgMi41OTc0MSAxNC44NTU1TDAuMDA5MzUzMiAyLjU3MjU0Qy0wLjAxNTg0MSAyLjQ1MjE1IDAuMDEwMjIxOSAyLjMyNzQ0IDAuMDgzMTk4MiAyLjIyODdDMC4xNTUzMDYgMi4xMjk5NyAwLjI2NTYzOSAyLjA2NTg4IDAuMzg4MTM1IDIuMDUyNjdMMTkuNTE5MiAwLjAwMjg0MTU1QzE5LjY1MTEgLTAuMDEyNzQ4MSAxOS43ODQyIDAuMDM2NjE5MiAxOS44NzcxIDAuMTMxMDIzQzE5Ljk3MDEgMC4yMjU0MjggMjAuMDE0MiAwLjM1OTY3MiAxOS45OTYgMC40OTA0NTJMMTguMjk0IDEzLjA5MDJDMTguMjY0NSAxMy4zMDUgMTguMDgxNCAxMy40NjYgMTcuODYzNCAxMy40NjZIMTIuODcwNkw1LjkyOTEyIDE4Ljc1MzhDNS44NTAwNiAxOC44MTM1IDUuNzU3MSAxOC44NDMgNS42NjQxNSAxOC44NDNaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
      }
    }
    .c-sidebar__feature--visitors {
      margin-bottom: 2px;
      .c-sidebar__icon {
        width: 25px;
        height: 25px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iIzhkYWJjNCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTUuOSAxNS4zOUEyIDIgMCAwMDE0IDE0aC0xdi0zYTEgMSAwIDAwLTEtMUg2VjhoMmExIDEgMCAwMDEtMVY1aDJhMiAyIDAgMDAyLTJ2LS40MWE3Ljk4IDcuOTggMCAwMTIuOSAxMi44ek05IDE3LjkzYTcuOTkgNy45OSAwIDAxLTYuNzktOS43Mkw3IDEzdjFjMCAxLjEuOSAyIDIgMnpNMTAgMGExMCAxMCAwIDEwMCAyMCAxMCAxMCAwIDAwMC0yMHoiLz48L3N2Zz4=");
      }
      .c-sidebar__icon--active {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTUuOSAxNS4zOUEyIDIgMCAwMDE0IDE0aC0xdi0zYTEgMSAwIDAwLTEtMUg2VjhoMmExIDEgMCAwMDEtMVY1aDJhMiAyIDAgMDAyLTJ2LS40MWE3Ljk4IDcuOTggMCAwMTIuOSAxMi44ek05IDE3LjkzYTcuOTkgNy45OSAwIDAxLTYuNzktOS43Mkw3IDEzdjFjMCAxLjEuOSAyIDIgMnpNMTAgMGExMCAxMCAwIDEwMCAyMCAxMCAxMCAwIDAwMC0yMHoiLz48L3N2Zz4=");
      }
    }
    .c-sidebar__feature--contacts {
      margin-bottom: 2px;
      .c-sidebar__icon {
        width: 23px;
        height: 23px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMyI+PGcgZmlsbD0iIzhkYWJjNCIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC4yNSkiPjxwYXRoIGQ9Ik0wIDE5LjAyYzAtLjU1LjQtMS4yLjktMS40NSAwIDAgNi44LTMuNTQgMTAuMDItMy41NCAzLjI2IDAgMTAuMDQgMy41NSAxMC4wNCAzLjU1LjQ4LjI0Ljg3LjkuODcgMS40NHYyLjVhMSAxIDAgMDEtLjk5IDFILjk5YTEgMSAwIDAxLS45OS0xeiIvPjxyZWN0IHdpZHRoPSIxMi4xMyIgaGVpZ2h0PSIxNC41NTYiIHg9IjQuODUyIiByeD0iNi4wNjUiLz48L2c+PC9zdmc+");
      }
      .c-sidebar__icon--active {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMyI+PGcgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC4yNSkiPjxwYXRoIGQ9Ik0wIDE5LjAyYzAtLjU1LjQtMS4yLjktMS40NSAwIDAgNi44LTMuNTQgMTAuMDItMy41NCAzLjI2IDAgMTAuMDQgMy41NSAxMC4wNCAzLjU1LjQ4LjI0Ljg3LjkuODcgMS40NHYyLjVhMSAxIDAgMDEtLjk5IDFILjk5YTEgMSAwIDAxLS45OS0xeiIvPjxyZWN0IHdpZHRoPSIxMi4xMyIgaGVpZ2h0PSIxNC41NTYiIHg9IjQuODUyIiByeD0iNi4wNjUiLz48L2c+PC9zdmc+");
      }
    }
    .c-sidebar__feature--campaigns {
      margin-bottom: 2px;
      .c-sidebar__icon {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iIzhkYWJjNCIgZD0iTTE5LjY5LjEyYS43LjcgMCAwMS4zLjcybC0yLjg2IDE3LjE0YS43LjcgMCAwMS0uNy42Ljc1Ljc1IDAgMDEtLjI3LS4wNmwtNS4wNS0yLjA3LTIuNyAzLjNhLjY2LjY2IDAgMDEtLjU1LjI1LjcuNyAwIDAxLS41OS0uMy43LjcgMCAwMS0uMTMtLjQxdi0zLjlMMTYuOCAzLjU3IDQuODYgMTMuOS40NSAxMi4xYS42Ni42NiAwIDAxLS40NS0uNjJjLS4wMS0uMy4xLS41MS4zNi0uNjZMMTguOTMuMWMuMTEtLjA3LjIzLS4xLjM2LS4xYS43LjcgMCAwMS40LjEyeiIvPjwvc3ZnPg==");
      }
      .c-sidebar__icon--active {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE5LjY5LjEyYS43LjcgMCAwMS4zLjcybC0yLjg2IDE3LjE0YS43LjcgMCAwMS0uNy42Ljc1Ljc1IDAgMDEtLjI3LS4wNmwtNS4wNS0yLjA3LTIuNyAzLjNhLjY2LjY2IDAgMDEtLjU1LjI1LjcuNyAwIDAxLS41OS0uMy43LjcgMCAwMS0uMTMtLjQxdi0zLjlMMTYuOCAzLjU3IDQuODYgMTMuOS40NSAxMi4xYS42Ni42NiAwIDAxLS40NS0uNjJjLS4wMS0uMy4xLS41MS4zNi0uNjZMMTguOTMuMWMuMTEtLjA3LjIzLS4xLjM2LS4xYS43LjcgMCAwMS40LjEyeiIvPjwvc3ZnPg==");
      }
    }
    .c-sidebar__feature--helpdesk {
      .c-sidebar__icon {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iIzhkYWJjNCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTYuMjkgMGwxLjA0IDEuNjFIMy44N2EuOS45IDAgMTAwIDEuODFIMTlWMjBIM2EyIDIgMCAwMS0yLTJWMmMwLTEuMS45LTIgMi0yeiIvPjwvc3ZnPg==");
      }
      .c-sidebar__icon--active {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTYuMjkgMGwxLjA0IDEuNjFIMy44N2EuOS45IDAgMTAwIDEuODFIMTlWMjBIM2EyIDIgMCAwMS0yLTJWMmMwLTEuMS45LTIgMi0yeiIvPjwvc3ZnPg==");
      }
    }
    .c-sidebar__feature--analytics {
      .c-sidebar__icon {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iIzhkYWJjNCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTMuMzMgMTUuNTZoMi4yM1YxMS4xaC0yLjIzem0tNC40NCAwaDIuMjJWNC40NEg4Ljl6bS00LjQ1IDBoMi4yM1Y3Ljc4SDQuNDR6TTE3Ljc4IDBIMi4yMkMxIDAgMCAxIDAgMi4yMnYxNS41NkMwIDE5IDEgMjAgMi4yMiAyMGgxNS41NkMxOSAyMCAyMCAxOSAyMCAxNy43OFYyLjIyQzIwIDEgMTkgMCAxNy43OCAweiIvPjwvc3ZnPg==");
      }
      .c-sidebar__icon--active {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTMuMzMgMTUuNTZoMi4yM1YxMS4xaC0yLjIzem0tNC40NCAwaDIuMjJWNC40NEg4Ljl6bS00LjQ1IDBoMi4yM1Y3Ljc4SDQuNDR6TTE3Ljc4IDBIMi4yMkMxIDAgMCAxIDAgMi4yMnYxNS41NkMwIDE5IDEgMjAgMi4yMiAyMGgxNS41NkMxOSAyMCAyMCAxOSAyMCAxNy43OFYyLjIyQzIwIDEgMTkgMCAxNy43OCAweiIvPjwvc3ZnPg==");
      }
    }
    .c-sidebar__feature--plugins {
      .c-sidebar__icon {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iIzhkYWJjNCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAxMi40NmExIDEgMCAwMTEtMWg2LjU3YTEgMSAwIDAxMSAxVjE5YTEgMSAwIDAxLTEgMUgxYTEgMSAwIDAxLTEtMXpNMCAxYTEgMSAwIDAxMS0xaDYuNTdhMSAxIDAgMDExIDF2Ni41NGExIDEgMCAwMS0xIDFIMWExIDEgMCAwMS0xLTF6bTExLjQzIDBhMSAxIDAgMDExLTFIMTlhMSAxIDAgMDExIDF2Ni41NGExIDEgMCAwMS0xIDFoLTYuNTdhMSAxIDAgMDEtMS0xeiIvPjwvc3ZnPg==");
      }
      .c-sidebar__icon--active {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAxMi40NmExIDEgMCAwMTEtMWg2LjU3YTEgMSAwIDAxMSAxVjE5YTEgMSAwIDAxLTEgMUgxYTEgMSAwIDAxLTEtMXpNMCAxYTEgMSAwIDAxMS0xaDYuNTdhMSAxIDAgMDExIDF2Ni41NGExIDEgMCAwMS0xIDFIMWExIDEgMCAwMS0xLTF6bTExLjQzIDBhMSAxIDAgMDExLTFIMTlhMSAxIDAgMDExIDF2Ni41NGExIDEgMCAwMS0xIDFoLTYuNTdhMSAxIDAgMDEtMS0xeiIvPjwvc3ZnPg==");
      }
    }
    .c-sidebar__feature--settings {
      .c-sidebar__icon {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iIzhkYWJjNCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTAgMTMuNWMtMS45OCAwLTMuNi0xLjU3LTMuNi0zLjVTOC4wMiA2LjUgMTAgNi41YzEuOTkgMCAzLjYgMS41NyAzLjYgMy41cy0xLjYxIDMuNS0zLjYgMy41em03LjY0LTIuNTJhNy41OCA3LjU4IDAgMDAwLTEuOTZsMi4xNy0xLjY1YS41LjUgMCAwMC4xMi0uNjRsLTIuMDUtMy40NmEuNTIuNTIgMCAwMC0uNjMtLjIybC0yLjU2IDFhNy41NiA3LjU2IDAgMDAtMS43NC0uOThMMTIuNTYuNDJhLjUuNSAwIDAwLS41LS40Mkg3Ljk1YS41LjUgMCAwMC0uNS40MmwtLjQgMi42NWMtLjYzLjI1LTEuMi41OS0xLjc0Ljk4bC0yLjU2LTFhLjUuNSAwIDAwLS42Mi4yMkwuMDcgNi43M2EuNDguNDggMCAwMC4xMi42NGwyLjE3IDEuNjVhNy43MiA3LjcyIDAgMDAwIDEuOTZMLjIgMTIuNjNhLjUuNSAwIDAwLS4xMi42NGwyLjA2IDMuNDZjLjEyLjIyLjQuMy42Mi4yMmwyLjU2LTFjLjU0LjQgMS4xMS43MyAxLjc0Ljk4bC40IDIuNjVhLjUuNSAwIDAwLjUuNDJoNC4xYS41LjUgMCAwMC41MS0uNDJsLjQtMi42NWMuNjItLjI1IDEuMi0uNTkgMS43My0uOThsMi41NiAxYy4yNC4wOS41IDAgLjYzLS4yMmwyLjA1LTMuNDZhLjUuNSAwIDAwLS4xMi0uNjR6Ii8+PC9zdmc+");
      }
      .c-sidebar__icon--active {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTAgMTMuNWMtMS45OCAwLTMuNi0xLjU3LTMuNi0zLjVTOC4wMiA2LjUgMTAgNi41YzEuOTkgMCAzLjYgMS41NyAzLjYgMy41cy0xLjYxIDMuNS0zLjYgMy41em03LjY0LTIuNTJhNy41OCA3LjU4IDAgMDAwLTEuOTZsMi4xNy0xLjY1YS41LjUgMCAwMC4xMi0uNjRsLTIuMDUtMy40NmEuNTIuNTIgMCAwMC0uNjMtLjIybC0yLjU2IDFhNy41NiA3LjU2IDAgMDAtMS43NC0uOThMMTIuNTYuNDJhLjUuNSAwIDAwLS41LS40Mkg3Ljk1YS41LjUgMCAwMC0uNS40MmwtLjQgMi42NWMtLjYzLjI1LTEuMi41OS0xLjc0Ljk4bC0yLjU2LTFhLjUuNSAwIDAwLS42Mi4yMkwuMDcgNi43M2EuNDguNDggMCAwMC4xMi42NGwyLjE3IDEuNjVhNy43MiA3LjcyIDAgMDAwIDEuOTZMLjIgMTIuNjNhLjUuNSAwIDAwLS4xMi42NGwyLjA2IDMuNDZjLjEyLjIyLjQuMy42Mi4yMmwyLjU2LTFjLjU0LjQgMS4xMS43MyAxLjc0Ljk4bC40IDIuNjVhLjUuNSAwIDAwLjUuNDJoNC4xYS41LjUgMCAwMC41MS0uNDJsLjQtMi42NWMuNjItLjI1IDEuMi0uNTkgMS43My0uOThsMi41NiAxYy4yNC4wOS41IDAgLjYzLS4yMmwyLjA1LTMuNDZhLjUuNSAwIDAwLS4xMi0uNjR6Ii8+PC9zdmc+");
      }
    }
    .c-sidebar__feature--setup {
      position: relative;
      margin-top: 34px;
      -webkit-animation-name: fadeIn;
      animation-name: fadeIn;
      -webkit-animation-duration: 0.35s;
      animation-duration: 0.35s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
      &:before {
        position: absolute;
        top: 4px;
        right: 4px;
        bottom: 4px;
        left: 4px;
        border-radius: 1px;
        content: "";
        background-color: #40b630;
      }
      &:active {
        .c-sidebar__label {
          color: #b6eaaf;
        }
        background-color: rgba(185, 226, 179, 0.25);
      }
      &:hover {
        .c-sidebar__label {
          color: #b6eaaf;
        }
        background-color: rgba(185, 226, 179, 0.2);
      }
      .c-sidebar__icon {
        width: 26px;
        height: 26px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMCAwaDI0djI0SDB6Ii8+PHBhdGggZmlsbD0iIzEyM2QwYyIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTAuMjcgMTcuMzVMNS4xMyAxMi4ybDUuMTQtNS4xNUw4LjcxIDUuNSAyIDEyLjJsNi43IDYuNzF6bTMuNDYgMGw1LjE0LTUuMTQtNS4xNC01LjE1IDEuNTYtMS41NkwyMiAxMi4ybC02LjcgNi43MXoiLz48L2c+PC9zdmc+");
      }
      .c-sidebar__label {
        color: #40b630;
      }
    }
    .c-sidebar__feature--upgrade {
      position: relative;
      margin-top: 34px;
      -webkit-animation-name: fadeIn;
      animation-name: fadeIn;
      -webkit-animation-duration: 0.35s;
      animation-duration: 0.35s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
      &:before {
        position: absolute;
        top: 4px;
        right: 4px;
        bottom: 4px;
        left: 4px;
        border-radius: 1px;
        content: "";
        background-color: #fd7b1f;
      }
      &:active {
        .c-sidebar__label {
          color: #ffe3d0;
        }
        background-color: rgba(255, 208, 165, 0.25);
      }
      &:hover {
        .c-sidebar__label {
          color: #ffe3d0;
        }
        background-color: rgba(255, 208, 165, 0.2);
      }
      .c-sidebar__icon {
        width: 24px;
        height: 23px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyMyI+PHBhdGggZmlsbD0iIzRjMjQwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNOC40OCA3LjMyTDExLjk1LjI1bDMuNSA3LjA3TDI0IDguNjhsLTUuNzIgNS42NCAxLjI4IDguMzctNy42LTQuMTUtNy42NCA0LjE1IDEuMzctOC4zN0wwIDguNjh6Ii8+PC9zdmc+");
      }
      .c-sidebar__label {
        color: #fd7b1f;
      }
    }
    .c-sidebar__feature--disabled {
      cursor: not-allowed;
    }
    .c-sidebar__feature--loading {
      -webkit-animation: sidebarFeatureLoading 2s linear infinite;
      animation: sidebarFeatureLoading 2s linear infinite;
    }
  }
}
.c-sidebar--mac {
  .c-sidebar__wrapper {
    margin-top: 60px;
  }
}
@media (max-width: 68.75em) {
  .c-sidebar {
    width: 60px;
    .c-sidebar__wrapper {
      margin-top: 45px;
      .c-sidebar__feature {
        margin-bottom: 8px;
        width: 40px;
        height: 40px;
        .c-sidebar__icon {
          width: 20px;
          height: 20px;
        }
        .c-sidebar__label {
          display: none;
        }
        .c-sidebar__notifications {
          right: 0;
          padding: 0 4px;
          height: 17px;
          font-size: 11px;
          line-height: 18px;
        }
      }
    }
  }
}
@media (max-height: 45em) {
  .c-sidebar {
    .c-sidebar__wrapper {
      .c-sidebar__feature--setup {
        margin-top: 12px;
        .c-sidebar__label {
          display: none;
        }
      }
      .c-sidebar__feature--upgrade {
        margin-top: 12px;
        .c-sidebar__label {
          display: none;
        }
      }
    }
  }
}
@media (max-height: 41.25em) {
  .c-sidebar {
    .c-sidebar__wrapper {
      margin-top: 22px;
      .c-sidebar__switcher {
        margin-bottom: 22px;
      }
    }
  }
}
@media (max-width: 34.3125em) {
  .c-sidebar {
    display: none;
  }
}
@media (max-height: 38.75em) {
  .c-sidebar {
    width: 60px;
    .c-sidebar__wrapper {
      .c-sidebar__feature {
        margin-bottom: 8px;
        width: 40px;
        height: 40px;
        .c-sidebar__icon {
          width: 20px;
          height: 20px;
        }
        .c-sidebar__label {
          display: none;
        }
        .c-sidebar__notifications {
          right: 0;
          padding: 0 4px;
          height: 17px;
          font-size: 11px;
          line-height: 18px;
        }
      }
    }
  }
}
@media (max-height: 35.625em) {
  .c-sidebar {
    .c-sidebar__wrapper {
      .c-sidebar__feature--setup {
        display: none;
      }
      .c-sidebar__feature--upgrade {
        display: none;
      }
    }
  }
}
</style>
